import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {IMeasurement, Measurement} from './models/measurement';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';
import {SensorTypeResolverService} from './resolvers/sensor-type-resolver.service';
import {SensorTypeBackendService} from './services-backend/sensor-type-backend.service';
import {SensorType} from './models/device-sensor';

@Injectable({
  providedIn: 'root'
})
export class MeasurementBackendService {

  constructor(
      private httpClient: HttpClient,
      private sensorTypeResolver: SensorTypeResolverService,
  ) { }


  getValues(ids: number [], start: Date, end: Date, deviceSensorIdToSensorTypeId: Map<number, number>):
      Observable<Measurement[]> {
        // console.log('Measurement service: getValues');
        const frequency = 'smartMeasurements';
        const desiredMarkerCount = 500;
        return this.httpClient.get<IMeasurement[]>
        (`${environment.url}/` + frequency + `/?format=json&max_results=${desiredMarkerCount}&ordering=measurement_time&device_sensor_ids=`
            + ids + `&sensor_data_start=` + start.toISOString() + `&sensor_data_end=` + end.toISOString())
            .pipe(map((res: IMeasurement[]) => res.map(measurement => {
                const sensorTypeId: number = deviceSensorIdToSensorTypeId.get(measurement.device_sensor_id);
                const sensorType: SensorType = this.sensorTypeResolver.sensorTypes.get(sensorTypeId);
                return Measurement.getFromIMeasurement(measurement, sensorType);
            })));
    }

}
