<mat-card>
	<mat-card-title>Ustawienia Organizacji</mat-card-title>
	<mat-card-content>
		<mat-form-field>
			<mat-label>Nazwa organizacji</mat-label>
			<input matInput placeholder="Nazwa..." [value]="organization.name" (input)="organizationNameChange($event)">
		</mat-form-field>
		<button class="save" [disabled]="!enableOrganizationNameButton" (click)="saveOrganizationName()">Zapisz</button>
		<button class="delete" (click)="deleteOrganization()">&times; Usuń organizację</button>
	</mat-card-content>
</mat-card>

<button class="back-button" (click)="router.navigate(['dashboard'])">Wstecz</button>
