import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {UserProfile} from '../models/user-profile';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {IUserAccount, UserAccount, UserAccountFactory} from '../models/user-account';

@Injectable({
    providedIn: 'root'
})
export class ProfileBackendService {

    constructor(
        private http: HttpClient,
    ) { }

    getProfile(): Observable<UserProfile> {
        const url = `${environment.url}/userProfile/`;
        return this.http.get<UserProfile>(url).pipe(map(userProfile => {
            return new UserProfile(userProfile);
        }));
    }

    setProfile(userProfile: UserProfile): Observable<UserProfile> {
        const url = `${environment.url}/userProfile/`;
        return this.http.put<UserProfile>(url, userProfile).pipe(map(res => {
            return new UserProfile(res);
        }));
    }

    getUserAccount(): Observable<UserAccount> {
        const url = `${environment.url}/accounts/user/`;
        return this.http.get<IUserAccount>(url).pipe(map(userAccount => {
            return UserAccountFactory.fromBackend(userAccount);
        }));
    }

    public editUserAccount(userAccount: UserAccount): Observable<UserAccount> {
        return this.http
            .put<IUserAccount>(
                `${environment.url}/accounts/user/`,
                UserAccountFactory.toBackend(userAccount))
            .pipe(
                map(ret => {
                    return UserAccountFactory.fromBackend(ret);
                })
            );
    }
}
