import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganizationBackendService} from '../../organization/organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {UserAccount} from '../../../models/user-account';
import {ProfileBackendService} from '../../../services-backend/profile-backend.service';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
    public enableUserEditButton = false;
    public userAccount: UserAccount;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private profileBackendService: ProfileBackendService,
        public authenticationService: AuthenticationService,
    ) { }

    ngOnInit(): void {
        this.route.parent.data.subscribe((data: { userAccount: UserAccount }) => {
            this.userAccount = data.userAccount;
        });
    }

    public userAccountFirstNameChange(event: Event): void {
        this.userAccount.first_name = (event.target as HTMLInputElement).value;
        this.enableUserEditButton = true;
    }

    public userAccountLastNameChange(event: Event): void {
        this.userAccount.last_name = (event.target as HTMLInputElement).value;
        this.enableUserEditButton = true;
    }

    public saveUser(): void {
        this.profileBackendService.editUserAccount(this.userAccount)
            .subscribe(ret => {
                this.userAccount = ret;
                this.alertService.success('Zaktualizowano imię i nazwisko');
                this.enableUserEditButton = false;
            });
    }
}
