import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {IWidget, Widget} from '../models/widget';
import {ErrorHandlerService} from '../services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetBackendService {

      constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    getWidget(id: number): Observable<Widget> {
        const url = `${environment.url}/widgets/${id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.get<IWidget>(url)).pipe(map(widget => {
            return new Widget(widget);
        }));
    }

    getWidgets(dashboardId: number): Observable<Widget[]> {
        const url = `${environment.url}/widgets/?dashboard_id=${dashboardId}`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.get<IWidget[]>(url)).pipe(
            map(widgets => { return widgets.map(el => new Widget(el));
        }));
    }

    deleteWidget(id: number): Observable<void> {
        const url = `${environment.url}/widgets/${id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.delete<void>(url));
    }

    setWidget(widget: Widget): Observable<Widget> {
        const url = `${environment.url}/widgets/${widget.id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.put<IWidget>(url, widget)).pipe(
            map(res => { return new Widget(res);
        }));
    }

    createWidget(widget: Widget): Observable<Widget> {
        const url = `${environment.url}/widgets/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.post<IWidget>(url, widget))
            .pipe(map(res => { return new Widget(res);
        }));
    }
}
